import React, { useCallback, useEffect } from 'react';
import { Alert, AlertColor, Box, Button, CircularProgress, Grid, IconButton, Modal, Snackbar, Stack, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { deleteProfile as studentDeleteProfile, putUserEmail as studentPutEmail, putUserPassword as studentPutPassword } from 'src/utils/studentApi';
import { deleteProfile as schoolDeleteProfile, putUserEmail as schoolPutEmail, putUserPassword as schoolPutPassword } from 'src/utils/schoolsApi';
import { StatusCodes } from 'http-status-codes';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import InputTextField from 'src/components/Form/InputTextField';
import { UoMBlue } from 'src/app/color';

const modalContentStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '30%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const updateEmailValidation = Yup.object({
  email: Yup
    .string()
    .trim()
    .email('Enter a valid email')
    .required('Email is required')
    .matches(
      localStorage.getItem('role') === 'school' ? /^[\w-.]+@((?!student\.)[\w-]+\.)+(edu|education|gov|vic)(\.[\w-]{2,4})?$/ : /^[\w-.]+@student\.unimelb\.edu\.au/,
      localStorage.getItem('role') === 'school'
        ? 'Email must be school email with edu, education, gov, or vic in it, student emails are not allowed'
        : 'Email must be a University of Melbourne student email',
    ),
  confirmEmail: Yup
    .string()
    .trim()
    .oneOf([Yup.ref('email'), ''], 'Email addresses do not match.')
    .required('Please repeat your email'),
});

const updatePasswordValidation = Yup.object({
  password: Yup
    .string()
    .required('Password is required')
    .min(8, 'Password should be of minimum 8 characters length'),
  confirmPassword: Yup
    .string()
    .oneOf([Yup.ref('password'), ''], 'Passwords do not match.')
    .required('Please repeat your password.'),
});

const deleteAccountValidation = Yup.object({
  email: Yup
    .string()
    .trim()
    .email('Enter a valid email')
    .required('Email is required'),
  password: Yup
    .string()
    .required('Password is required'),
});

async function studentSettingsAction(action: String, requestData: any) {
  if (action === 'update-email') {
    const response = studentPutEmail(localStorage.getItem('userId') || '', requestData);
    const { data } = await response;
    return data;
  }
  if (action === 'update-password') {
    const response = studentPutPassword(localStorage.getItem('userId') || '', requestData);
    const { data } = await response;
    return data;
  }
  if (action === 'delete-account') {
    const response = studentDeleteProfile(localStorage.getItem('userId') || '', requestData);
    const { data } = await response;
    return data;
  }
  return '';
}

async function schoolSettingsAction(action: String, requestData: any) {
  if (action === 'update-email') {
    const response = schoolPutEmail(localStorage.getItem('userId') || '', requestData);
    const { data } = await response;
    return data;
  }
  if (action === 'update-password') {
    const response = schoolPutPassword(localStorage.getItem('userId') || '', requestData);
    const { data } = await response;
    return data;
  }
  if (action === 'delete-account') {
    const response = schoolDeleteProfile(localStorage.getItem('userId') || '', requestData);
    const { data } = await response;
    return data;
  }
  return '';
}

async function settingsAction(action: String, requestData: any) {
  return localStorage.getItem('role') === 'school' ? schoolSettingsAction(action, requestData) : studentSettingsAction(action, requestData);
}

function AccountSettings() {
  const navigate = useNavigate();

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [actionMessage, setActionMessage] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [alertType, setAlertType] = React.useState<AlertColor | undefined>();
  const setSnackbarMessage = (message: string, type: AlertColor) => {
    setLoading(false);
    setSnackbarOpen(true);
    setActionMessage(message);
    setAlertType(type);
  };

  const [modalOpen, setModalOpen] = React.useState(false);
  const [modalContent, setModalContent] = React.useState('');
  const handleModalOpen = (contentType: string) => {
    setModalContent(contentType);
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalContent('');
    setModalOpen(false);
  };

  const updateEmailForm = useFormik({
    initialValues: {
      email: '',
      confirmEmail: '',
    },
    validationSchema: updateEmailValidation,
    enableReinitialize: true,
    onSubmit: useCallback(async (value: any) => {
      setLoading(true);
      settingsAction('update-email', value.email)
        .then((result) => {
          setSnackbarMessage(result, 'success');
          setTimeout(() => {
            localStorage.clear();
            navigate('/login');
          }, 3000);
        })
        .catch((error) => {
          setSnackbarMessage(error.response.data, 'error');
        });
    }, [navigate]),
  });

  const updatePasswordForm = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: updatePasswordValidation,
    enableReinitialize: true,
    onSubmit: useCallback(async (value: any) => {
      setLoading(true);
      settingsAction('update-password', { password: value.password })
        .then((result) => {
          setSnackbarMessage(result, 'success');
          handleModalClose();
        })
        .catch((error) => {
          setSnackbarMessage(error.response.data, 'error');
        });
    }, []),
  });

  const deleteAccountForm = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: deleteAccountValidation,
    enableReinitialize: true,
    onSubmit: useCallback(async (value: any) => {
      setLoading(true);
      settingsAction('delete-account', { email: value.email, password: value.password })
        .then((result) => {
          setSnackbarMessage(result, 'success');
          setTimeout(() => {
            localStorage.clear();
            navigate('/login');
          }, 3000);
        })
        .catch((error) => {
          setSnackbarMessage(error.response.data, 'error');
        });
    }, [navigate]),
  });

  const updateEmailRenderer = (
    <form noValidate className="form" onSubmit={updateEmailForm.handleSubmit}>
      <h4 className="form-title" style={{ color: UoMBlue }}>
        Update Email
      </h4>
      <p>
        Note: You will be required to verify your email once you update it.
      </p>
      <InputTextField formik={updateEmailForm} inputName="email" label="New Email" required />
      <InputTextField formik={updateEmailForm} inputName="confirmEmail" label="Confirm Email" required />
      <Stack direction="row" spacing={2}>
        <Button
          className="button"
          disabled={loading}
          type="submit"
          variant="contained"
          startIcon={loading ? <CircularProgress color="inherit" size="14px" /> : null}
          sx={{ mt: 3, mb: 2 }}
        >
          Update
        </Button>
        <Button
          className="button"
          disabled={loading}
          onClick={handleModalClose}
          variant="outlined"
          style={{ marginTop: '24px', marginBottom: '16px' }}
          sx={{ mt: 3, mb: 2 }}
        >
          Cancel
        </Button>
      </Stack>
    </form>
  );

  const updatePasswordRenderer = (
    <form noValidate className="form" onSubmit={updatePasswordForm.handleSubmit}>
      <h4 className="form-title" style={{ color: UoMBlue }}>
        Update Password
      </h4>
      <InputTextField formik={updatePasswordForm} inputName="password" label="New Password" required type="password" />
      <InputTextField formik={updatePasswordForm} inputName="confirmPassword" label="Confirm Password" required type="password" />
      <Stack direction="row" spacing={2}>
        <Button
          className="button"
          disabled={loading}
          type="submit"
          variant="contained"
          startIcon={loading ? <CircularProgress color="inherit" size="14px" /> : null}
          sx={{ mt: 3, mb: 2 }}
        >
          Update
        </Button>
        <Button
          className="button"
          disabled={loading}
          onClick={handleModalClose}
          variant="outlined"
          style={{ marginTop: '24px', marginBottom: '16px' }}
          sx={{ mt: 3, mb: 2 }}
        >
          Cancel
        </Button>
      </Stack>
    </form>
  );

  const deleteAccountRenderer = (
    <form noValidate className="form" onSubmit={deleteAccountForm.handleSubmit}>
      <h4 className="form-title" style={{ color: UoMBlue }}>
        Delete Profile
      </h4>
      <p>
        Note: This action is irreversible. If you wish to use your account again, you will have to create a new one.
      </p>
      <InputTextField formik={deleteAccountForm} inputName="email" label="Email" required />
      <InputTextField formik={deleteAccountForm} inputName="password" label="Password" required type="password" />
      <Stack direction="row" spacing={2}>
        <Button
          className="button"
          disabled={loading}
          type="submit"
          variant="contained"
          startIcon={loading ? <CircularProgress color="inherit" size="14px" /> : null}
          color="error"
          sx={{ mt: 3, mb: 2 }}
        >
          Delete
        </Button>
        <Button
          className="button"
          onClick={handleModalClose}
          variant="outlined"
          style={{ marginTop: '24px', marginBottom: '16px' }}
          sx={{ mt: 3, mb: 2 }}
        >
          Cancel
        </Button>
      </Stack>
    </form>
  );

  let modalRenderer = null;
  if (modalContent === 'update-email') {
    modalRenderer = updateEmailRenderer;
  } else if (modalContent === 'update-password') {
    modalRenderer = updatePasswordRenderer;
  } else if (modalContent === 'delete-account') {
    modalRenderer = deleteAccountRenderer;
  }

  return (
    <Grid container className="account-settings" sx={{ bgcolor: 'white', margin: '16px', padding: '24px' }}>
      <Snackbar
        open={snackbarOpen}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ marginTop: '3em' }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={alertType}
          variant="filled"
          sx={{ width: '100%' }}
        >
          { actionMessage }
        </Alert>
      </Snackbar>
      <Grid xs={12}>
        <Typography
          variant="h1"
          sx={{ fontSize: 26, color: 'primary.main' }}
        >
          Account Settings
        </Typography>
      </Grid>
      <Stack spacing={2}>
        <Typography
          variant="body2"
          sx={{ marginTop: '16px' }}
        >
          <b>
            Email Address:&nbsp;
          </b>
          {localStorage.getItem('email')}
          <IconButton
            aria-label="edit"
            color="primary"
            onClick={() => handleModalOpen('update-email')}
          >
            <EditIcon />
          </IconButton>
        </Typography>
        <Button
          sx={{ width: 'fit-content' }}
          variant="contained"
          onClick={() => handleModalOpen('update-password')}
        >
          Update Password
        </Button>
        <Button
          sx={{ width: 'fit-content' }}
          variant="contained"
          color="error"
          onClick={() => handleModalOpen('delete-account')}
        >
          Delete Profile
        </Button>
      </Stack>
      <Modal
        open={modalOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalContentStyle}>
          { modalRenderer }
        </Box>
      </Modal>
    </Grid>
  );
}

export default AccountSettings;

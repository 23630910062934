import { useNavigate } from 'react-router-dom';
import { Link } from '@mui/material';
import React from 'react';
import '../Register.scss';
import { UoMBlue } from 'src/app/color';
import UniConnectEDlogo from '../../Logo/UniConnectEDlogo';

function SuccessCheckEmail() {
  localStorage.clear();
  return (
    <div className="background" style={{ backgroundColor: UoMBlue, height: '100vh' }}>
      <div className="register-form" style={{ height: '100vh' }}>
        <div className="form" style={{ justifyContent: 'center', alignItems: 'center' }}>
          <div className="logobox">
            <UniConnectEDlogo />
          </div>
          <h1 className="title" style={{ color: UoMBlue }}>Email verified successfully</h1>
          <br />
          <p>
            Your account is verified.
          </p>
          <br />
          <p>
            Thank you for joining UniConnectED.
            {' '}
            <br />
            {' '}
            Please use your email and password to
            {' '}
            <Link href="/login">
              sign in.
            </Link>

          </p>
        </div>
      </div>
    </div>
  );
}

export default SuccessCheckEmail;

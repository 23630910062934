/* eslint-disable @typescript-eslint/indent */
import React from 'react';
import { ThemeProvider } from '@mui/material';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { AdminRoute, ProtectedRoute, PublicRoute, SchoolRoute, StudentRoute } from 'src/components/RouteType/RouteType';
import Profile from 'src/pages/Profile/ProfileView';
import ProfileEdit from 'src/pages/Profile/ProfileEdit';
import AuthLayout from 'src/components/AuthLayout/AuthLayout';
import CheckEmail from 'src/components/Register/Verify/CheckEmail';
import ConfirmEmail from 'src/components/Register/Verify/ConfirmEmail';
import TeacherCandidatesPage from 'src/pages/TeacherCandidatesPage/TeacherCandidatesPage';
import VerifyStudentSignupLink from 'src/components/StudentSignup/VerifySignupLink';
import VerifySchoolSignupLink from 'src/components/Register/VerifySignupLink';
import ForgottenPassword from 'src/components/ForgottenPassword/SchoolStudent/ForgottenPassword';
import VerifyEmail from 'src/components/ForgottenPassword/Verify/VerifyEmail';
import ForbiddenPage from 'src/pages/ForbiddenPage/ForbiddenPage';
import NotFoundPage from 'src/pages/NotFoundPage/NotFoundPage';
import AdminLoginPage from 'src/pages/AdminLoginPage/AdminLoginPage';
import AdminForgottenPassword from 'src/components/ForgottenPassword/Admin/ForgottenPassword';
import AdminCheckEmail from 'src/components/ForgottenPassword/Admin/CheckEmail';
import SchoolsListPage from 'src/pages/SchoolsListPage/SchoolsListPage';
import AdminDashboard from 'src/pages/Admin/AdminDashboard';
import Interest from 'src/pages/Interest/Interest';
import Landing from 'src/pages/Landing/Landing';
import Home from 'src/pages/Landing/Home';
import Team from 'src/pages/Landing/Team';
import About from 'src/pages/Landing/About';
import Resources from 'src/pages/Landing/Resources';
import Contact from 'src/pages/Landing/Contact';
import AddExternalPartner from 'src/pages/Admin/AddExternalPartner';
import AddExternalStudent from 'src/pages/Admin/AddExternalStudent';
import AccountSettings from 'src/pages/AccountSettings/AccountSettings';
import theme from './theme';
import Login from '../components/Login/Login';
import RegisterSchool from '../components/Register/Register';
import SignupPage from '../pages/Student/SignupPage';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={(
              <PublicRoute>
                <Landing>
                  <Home />
                </Landing>
              </PublicRoute>
              )}
          />

          <Route
            path="/home"
            element={(
              <PublicRoute>
                <Landing>
                  <Home />
                </Landing>
              </PublicRoute>
              )}
          />

          <Route
            path="/team"
            element={(
              <PublicRoute>
                <Landing>
                  <Team />
                </Landing>
              </PublicRoute>
              )}
          />

          <Route
            path="/about"
            element={(
              <PublicRoute>
                <Landing>
                  <About />
                </Landing>
              </PublicRoute>
              )}
          />

          <Route
            path="/resources"
            element={(
              <PublicRoute>
                <Landing>
                  <Resources />
                </Landing>
              </PublicRoute>
              )}
          />
          <Route
            path="/contact"
            element={(
              <PublicRoute>
                <Landing>
                  <Contact />
                </Landing>
              </PublicRoute>
              )}
          />

          <Route
            path="/login"
            element={(
              <PublicRoute>
                <Landing disableCopyright disableHeader>
                  <Login />
                </Landing>
              </PublicRoute>
              )}
          />

          <Route
            path="/admin/login"
            element={(
              <PublicRoute>
                <AdminLoginPage />
              </PublicRoute>
              )}
          />

          <Route
            path="/register-student"
            element={(
              <PublicRoute>
                <SignupPage />
              </PublicRoute>
            )}
          />

          <Route
            path="/register-school"
            element={(
              <PublicRoute>
                <RegisterSchool />
              </PublicRoute>
              )}
          />
          <Route
            path="/admin/forgotten-password"
            element={(
              <PublicRoute>
                <AdminForgottenPassword />
              </PublicRoute>
              )}
          />
          <Route
            path="/forgotten-password"
            element={(
              <PublicRoute>
                <ForgottenPassword />
              </PublicRoute>
              )}
          />
          <Route
            path="/admin/check-email"
            element={(
              <PublicRoute>
                <AdminCheckEmail />
              </PublicRoute>
              )}
          />
          <Route
            path="/check-email"
            element={(
              <PublicRoute>
                <CheckEmail />
              </PublicRoute>
              )}
          />
          <Route
            path="/school/reset-password"
            element={(
              <PublicRoute>
                <VerifyEmail />
              </PublicRoute>
              )}
          />
          <Route
            path="/admin/reset-password"
            element={(
              <PublicRoute>
                <VerifyEmail />
              </PublicRoute>
              )}
          />
          <Route
            path="/student/register-external-student"
            element={(
              <PublicRoute>
                <VerifyStudentSignupLink />
              </PublicRoute>
              )}
          />
          <Route
            path="/school/register-external-partner"
            element={(
              <PublicRoute>
                <VerifySchoolSignupLink />
              </PublicRoute>
              )}
          />
          <Route
            path="/student/reset-password"
            element={(
              <PublicRoute>
                <VerifyEmail />
              </PublicRoute>
              )}
          />
          <Route
            path="/school/confirm-email"
            element={(
              <PublicRoute>
                <ConfirmEmail />
              </PublicRoute>
              )}
          />
          <Route
            path="/student/confirm-email"
            element={(
              <PublicRoute>
                <ConfirmEmail />
              </PublicRoute>
              )}
          />

          <Route
            path="/profile"
            element={(
              <ProtectedRoute>
                <AuthLayout component={<Profile />} />
              </ProtectedRoute>
              )}
          />

          <Route
            path="/settings"
            element={(
              <ProtectedRoute>
                <AuthLayout component={<AccountSettings />} />
              </ProtectedRoute>
              )}
          />

          <Route
            path="/profile-edit"
            element={(
              <ProtectedRoute>
                <AuthLayout component={<ProfileEdit />} />
              </ProtectedRoute>
              )}
          />

          <Route
            path="/forbidden"
            element={(
              <ProtectedRoute>
                <ForbiddenPage />
              </ProtectedRoute>
              )}
          />

          <Route
            path="/favourites"
            element={(
              <SchoolRoute>
                <AuthLayout component={<Interest />} />
              </SchoolRoute>
              )}
          />

          <Route
            path="/uomstudents"
            element={(
              <SchoolRoute>
                <AuthLayout component={<TeacherCandidatesPage />} />
              </SchoolRoute>
              )}
          />

          <Route
            path="/schools-list"
            element={(
              <StudentRoute>
                <AuthLayout component={<SchoolsListPage />} />
              </StudentRoute>
              )}
          />

          <Route
            path="/admin/dashboard"
            element={(
              <AdminRoute>
                <AuthLayout component={<AdminDashboard />} />
              </AdminRoute>
              )}
          />

          <Route
            path="/admin/external-student"
            element={(
              <AdminRoute>
                <AuthLayout component={<AddExternalStudent />} />
              </AdminRoute>
              )}
          />

          <Route
            path="/admin/external-partner"
            element={(
              <AdminRoute>
                <AuthLayout component={<AddExternalPartner />} />
              </AdminRoute>
              )}
          />

          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;

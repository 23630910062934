import React, { useCallback, useEffect, useState } from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link, useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import { adminMainListItem, schoolMainListItem, studentMainListItem } from 'src/components/MiniDrawer/components/ListItemTable/ListItemTable';
import { Menu, MenuItem, useMediaQuery } from '@mui/material';
import { UoMBlue } from 'src/app/color';
import { Drawer, AppBar, DrawerHeader } from './MiniDrawer.style';

function MiniDrawer() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState('');
  const [anchorSettings, setAnchorSettings] = React.useState<null | HTMLElement>(null);
  const openSettings = Boolean(anchorSettings);
  const mobileSize = useMediaQuery(theme.breakpoints.down('sm'));
  const role = localStorage.getItem('role');
  const updatePageName = useCallback((list: any) => {
    const urlElements = window.location.href.split('/');
    if (role !== 'admin') {
      list.forEach((element: any) => {
        if (element.link === urlElements[urlElements.length - 1]) {
          setPage(element.name);
        }
      });
    } else {
      list.forEach((element: any) => {
        if (element.link === `admin/${urlElements[urlElements.length - 1]}`) {
          setPage(element.name);
        }
      });
    }
  }, [role]);

  const siderBarItemMapping = (handleClick: Function) => {
    let itemList: any[];
    switch (role) {
      case 'student':
        itemList = studentMainListItem;
        break;
      case 'school':
        itemList = schoolMainListItem;
        break;
      case 'admin':
        itemList = adminMainListItem;
        break;
      default:
        itemList = [];
    }
    return itemList.map((item, _index) => (
      <ListItem key={item.name} disablePadding sx={{ display: 'block' }}>
        <ListItemButton
          selected={page === item.name}
          onClick={(e) => handleClick(e, item.name)}
          component={Link}
          to={`/${item.link}`}
          sx={{
            minHeight: 48,
            justifyContent: open ? 'initial' : 'center',
            px: 2.5,
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : 'auto',
              justifyContent: 'center',
            }}
          >
            {item.icon}
          </ListItemIcon>
          <ListItemText primary={item.name} sx={{ opacity: open ? 1 : 0 }} />
        </ListItemButton>
      </ListItem>
    ));
  };

  useEffect(() => {
    updatePageName([...schoolMainListItem, ...studentMainListItem, ...adminMainListItem]);
  }, [updatePageName]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleLogout = () => {
    const accountRole = role;
    localStorage.clear();
    if (accountRole === 'admin') {
      navigate('/admin/login');
    } else {
      navigate('/login');
    }
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClick = (event: any, name: string) => {
    setPage(name);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} style={{ backgroundColor: UoMBlue }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            {page}
          </Typography>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => setAnchorSettings(e.currentTarget)}
          >
            <ManageAccountsIcon />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorSettings}
            open={openSettings}
            onClose={() => setAnchorSettings(null)}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem
              onClick={() => {
                navigate('/settings');
                setAnchorSettings(null);
              }}
            >
              Account Settings
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      {(open && mobileSize) || !mobileSize ? (
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <div style={{ width: '100px', display: 'contents' }}>
              <img style={{ width: '50px' }} src="/images/uniconnected_logo.svg" alt="logo" />
              <Typography className="beta" variant="h1" sx={{ fontSize: 18, color: 'primary.main', ml: 1 }}>UniConnectED</Typography>
            </div>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            {siderBarItemMapping(handleClick)}
          </List>
          <Divider />
          <List>
            <ListItem key="Signout" disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                onClick={handleLogout}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary="Sign Out" sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>

          </List>
        </Drawer>
      ) : ''}

    </Box>
  );
}

export default MiniDrawer;

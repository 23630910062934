import React from 'react';
import Box from '@mui/material/Box';

function UniConnectEDlogo(props: any) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        flexGrow: 1,
      }}
    >
      <p style={{ margin: 0 }}>
        <a href="/">
          <img
            alt="UniConnectED logo"
            src="/images/uniconnected_logo.svg"
            style={{
              width: '96px',
            }}
          />
        </a>
      </p>
    </Box>
  );
}

export default UniConnectEDlogo;
